import {
	DISTANCE_FROM_ITEM,
	DISTANCE_FROM_IN_EDITOR_ELEMENT,
	VERTICAL_MARGIN,
	TOP_BAR_HEIGHT,
	BOTTOM_VERTICAL_MARGIN,
} from './measurementConstants';
import { BLOG_POST_ITEM_MODULE_COMPLETE_KEY } from './PreviewMessage';
import type { TemplateBodiesQuery as TemplateBodiesQueryResponse } from './__types__/TemplateBodiesQuery';

export type TemplatePreviewTemplate = {
	name: string | null;
	contentBlueprintId: string | null;
	templateId: string | null;
	blueprintModuleCompleteKey: string | null;
	itemModuleCompleteKey: string | null;
	iconURL: string | null;
	darkModeIconURL?: string | null;
	styleClass: string | null;
};

export const calculatePreviewPosition = (
	previewRect: DOMRect,
	itemRect: DOMRect,
	hasDynamicPositioning = false,
	isBottomPositioned = false,
	isHorizontallyCentered = false,
) => {
	const { scrollY } = window;

	let top = scrollY + (itemRect.top + itemRect.height / 2 - previewRect.height / 2);

	if (top < scrollY + TOP_BAR_HEIGHT + VERTICAL_MARGIN) {
		top = scrollY + TOP_BAR_HEIGHT + VERTICAL_MARGIN;
	}
	if (top + previewRect.height > scrollY + window.innerHeight - VERTICAL_MARGIN) {
		top = scrollY + window.innerHeight - VERTICAL_MARGIN - previewRect.height;
	}
	if (isBottomPositioned) {
		top = scrollY + itemRect.top - previewRect.height - BOTTOM_VERTICAL_MARGIN;
	}

	let left = hasDynamicPositioning
		? calculateDynamicPreviewPosition(previewRect, itemRect)
		: itemRect.left - previewRect.width - DISTANCE_FROM_ITEM;

	if (isHorizontallyCentered) {
		left = itemRect.left + itemRect.width / 2 - previewRect.width / 2;
	}

	return { top, left };
};

const calculateDynamicPreviewPosition = (previewRect: DOMRect, itemRect: DOMRect) => {
	const { screenWidth } = getWindowDimensions();
	const elementXPosition = (itemRect.left + itemRect.right) / 2;

	return elementXPosition < screenWidth / 2
		? itemRect.right + DISTANCE_FROM_IN_EDITOR_ELEMENT
		: itemRect.left - previewRect.width - DISTANCE_FROM_IN_EDITOR_ELEMENT;
};

export const getTemplateId = (template: TemplatePreviewTemplate) => {
	return (template.contentBlueprintId || template.templateId || template.itemModuleCompleteKey)!;
};

export const getBodyForTemplate = (
	data?: TemplateBodiesQueryResponse,
	template?: TemplatePreviewTemplate,
) => {
	const nodes = data?.templateBodies?.nodes;

	if (!template || !nodes) {
		return;
	}

	const templateId = getTemplateId(template);
	return nodes.find((node) => node && node.id === templateId) ?? undefined;
};

export const getTemplateIdsToFetch = (
	templates: TemplatePreviewTemplate[],
	templateIndex: number,
	chunkSize: number,
) => {
	if (templateIndex < 0) {
		return [];
	}

	const chunk = Math.floor(templateIndex / chunkSize);
	const startIndex = chunk * chunkSize;
	return templates.slice(startIndex, startIndex + chunkSize).map(getTemplateId);
};

export const isValidPreviewRepresentation = (representation: string) => {
	return ['view', 'atlas_doc_format'].includes(representation);
};

export const hasCustomPreview = (template?: TemplatePreviewTemplate) => {
	if (!template) {
		return false;
	}
	return template.itemModuleCompleteKey === BLOG_POST_ITEM_MODULE_COMPLETE_KEY;
};

const getWindowDimensions = () => {
	const { innerWidth: screenWidth } = window;
	return {
		screenWidth,
	};
};
